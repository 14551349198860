// All sorts of helpers functions used within the app router
import { formatInTimeZone } from 'date-fns-tz';
import { enGB } from 'date-fns/locale';

// react query config
export const queryConfig = {
  staleTime: 1000 * 3, // 3 seconds
  refetchOnWindowFocus: false,
};

// Parse {eventtime} notations in text to actual dates
export const parseEventTimes = (textToParse: string, dateToDisplay?: Date) => {
  if (!textToParse) return '';
  if (!dateToDisplay || !(dateToDisplay instanceof Date)) return textToParse;
  /* Find all date notations, e.g. {eventtime;DD.MM.YYYY}, in text */
  const foundDateNotations = textToParse.match(/({\s*eventtime\s*(}|\s*;.+?}))+/g);
  /* If no dates found, return original text */
  if (!foundDateNotations || foundDateNotations?.length === 0) {
    return textToParse;
  }
  let newText = textToParse;
  /* Loop through all the notations found */
  foundDateNotations?.forEach((dateNotation) => {
    /* Remove brackets, spaces, and 'eventtime;' from the 
      notation to get the optional date formattings */
    const dateFormattings = dateNotation.replace(/({|}|eventtime\s*;*)+/g, '');
    const dateWithNoBracket = dateFormattings.replace(/\[|\]/g, "'");

    /* Get the date formatter from the syntax */
    const dateFormatter = dateWithNoBracket.split(';')[0];

    /* FYI: separate UTC offset formatter was removed due to new timezone support in normal formatter! */

    /* Get user timezone */
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    /* Format date using date formatter. Defaults to 'dd.MM.yyyy' if formatter wasn't set */
    // TODO: if more event-site language versions are added, handle locales here
    const dateAsText = formatInTimeZone(dateToDisplay, tz, dateFormatter || 'dd.MM.yyyy', { locale: enGB });
    /* Replace the date notation in text with the actual date */
    newText = newText.replace(dateNotation, dateAsText);
  });
  return newText;
};
