import { WowzaStreamState } from './index';

export const ClientToServerMessageType = {
  moderationStart: 'moderationStart',
  receiveMessageboardMessages: 'receiveMessageboardMessages',
  liveStart: 'liveStart',
  liveEnd: 'liveEnd',

  receiveEventProgress: 'receiveEventProgress',
  receiveEventViewers: 'receiveEventViewers',
  receiveEventStateUpdates: 'receiveEventStateUpdates',
  receiveEventUpdates: 'receiveEventUpdates',
  receiveEventSaves: 'receiveEventSaves',
  receiveOskariMeetingUpdates: 'receiveOskariMeetingUpdates',
  receivePollTriggerUpdates: 'receivePollTriggerUpdates',
  receivePollSettingsUpdates: 'receivePollSettingsUpdates',
  receivePollAnswerUpdates: 'receivePollAnswerUpdates',
  receiveReactionsUpdates: 'receiveReactionsUpdates',
  receiveEventFieldsUpdates: 'receiveEventFieldsUpdates',
  joinWebStudio: 'joinWebStudio',
  leaveWebStudio: 'leaveWebStudio',

  toggleChatMsgReaction: 'toggleChatMsgReaction',
  playerStart: 'playerStart',
  receiveOskariOnlineStatus: 'receiveOskariOnlineStatus',
  receiveOnlineTokenStatus: 'receiveOnlineTokenStatus',
} as const;
export type ClientToServerMessageType = keyof typeof ClientToServerMessageType;

export const ServerToClientMessageType = {
  slideChange: 'slideChange',
  sendChatMsg: 'sendChatMsg',

  updateChatMsg: 'updateChatMsg',
  discardChatMsg: 'discardChatMsg',
  deleteChatMsg: 'deleteChatMsg',
  chatMsgsOrderUpdate: 'chatMsgsOrderUpdate',
  toPresenterChatMsg: 'toPresenterChatMsg',

  presenterSlideChange: 'presenterSlideChange',
  pollTriggered: 'pollTriggered',
  currentViewers: 'currentViewers',
  currentPresenters: 'currentPresenters',
  eventStateUpdated: 'eventStateUpdated',
  eventStateUpdatedRealTime: 'eventStateUpdatedRealTime',
  eventUpdated: 'eventUpdated',
  eventSaved: 'eventSaved',
  eventFieldsUpdated: 'eventFieldsUpdated',
  eventFieldsUpdated_v2: 'eventFieldsUpdated_v2',

  submitReaction: 'submitReaction',

  streamState: 'streamState',

  webStudioEvent: 'webStudioEvent',

  liveSessionCreated: 'liveSessionCreated',
  liveSessionUpdated: 'liveSessionUpdated',
  liveSessionEnded: 'liveSessionEnded',

  slidesVideo: 'slidesVideo',
  videoProcessing: 'videoProcessing',
  processingReady: 'processingReady',

  pollAnswersUpdated: 'pollAnswersUpdated',
  generateSubtitles: 'generateSubtitles',
  videoProcessingError: 'videoProcessingError',
  currentOskariOnlineMembers: 'currentOskariOnlineMembers',
  tokenConnected: 'tokenConnected',
  tokenDisconnected: 'tokenDisconnected',
} as const;
export type ServerToClientMessageType = keyof typeof ServerToClientMessageType;

export const RoomPrefix = {
  webStudio: 'webStudio',
} as const;

export const WebStudioEventType = {
  setupLiveStarted: 'setupLiveStarted',
  goLiveStarted: 'goLiveStarted',
  pauseLiveStarted: 'pauseLiveStarted',
  resumeLiveStarted: 'resumeLiveStarted',
  endLiveStarted: 'endLiveStarted',

  startPresenting: 'startPresenting',
  stopPresenting: 'stopPresenting',

  webStudioStateUpdate: 'webStudioStateUpdate',
} as const;
export type WebStudioEventType = keyof typeof WebStudioEventType;

export interface SocketPayload<T = Record<string, unknown>> {
  sentAt: string;
  data: T;
}

/**
 * new stuff
 *
 */
export type ServerToClientEventStateUpdated = {
  state: number;
};

export type ServerToClientWebStudioEvent = SocketPayload<{
  webStudioEventType: WebStudioEventType;
  webStudioEventData: Record<string, unknown>;
}>;
export type ServerToClientSlideChange = {
  slide: number;
  slideIndex: number;
  slideSet: number;
  socketId?: string;
  webStudioUserId?: string;
};
export type ServerToClientCurrentViewers = number;

export type ServerToClientTokenConnected = {
  token: string;
};
export type ServerToClientTokenDisconnected = {
  token: string;
};

export type ServerToClientVideoProcessing = {
  jobId: string;
  type: string;
  eventId: string;
  file: string;
  msg: string;
  language: string;
  progress?: number;
  err?: {
    status: string;
    msg: string;
    worker: string;
  };
};

export type ServerToClientVideoProcessingError = {
  jobId: string;
  type: string;
  file: string;
  eventId: string;
  err: {
    status: string;
    msg: string;
    worker: string;
  };
  data?: any;
};

export type ServerToClientPollAnswersUpdated = {
  pollId: string;
  eventId: string;
  answers: {
    color: string;
    count: number;
    title: string;
    _id: string;
  }[];
  votes?: {
    answerIds: [];
    values: string[];
    extraTextValue: string;
    _id: string;
    voteType: string;
    createdAt: string;
  }[];
};
export type ServerToClientEventFieldsUpdatedPayload_v2 = {
  eventId: string;
  time: string;
  requestId?: string;
  sessionId?: string;
  fields: {
    name: string;
    value: string;
  }[];
};
export type ServerToClientGenerateSubtitles = {
  jobId: string;
  type: 'generateSubtitlesProgress' | 'generateSubtitlesSuccess' | 'generateSubtitlesError';
  eventId: string;
  data?: {
    progress: number;
  };
  result: {
    autogenerated: boolean;
    filename: string;
    label: string;
    mimetype: string;
    path: string;
    size: number;
  };
};
export type ServerToClientEventSavedPayload = {
  updatedAt: string;
  updatedBy: {
    _id: string;
    username: string;
    firstName: string;
    lastName: string;
  };
  _id: string;
};
export type ServerToClientCurrentOskariOnlineMembers = number;
export type ServerToClientStreamState = {
  streamingState: WowzaStreamState;
  // activeEvent: null;
  // stream: {};
  // recorders: 0;
  streamTargets: StreamTarget[];
  statistics?: {
    //   "serverName": "entry1",
    //   "applicationInstance": "_definst_",
    //   "name": "stream1",
    uptime: number;
    //   "bytesIn": 0,
    //   "bytesOut": 0,
    bytesInRate?: number;
    //   "bytesOutRate": 0,
    //   "totalConnections": 0,
    //   "connectionCount": {
    //     "RTMP": 0,
    //     "MPEGDASH": 0,
    //     "CUPERTINO": 0,
    //     "SANJOSE": 0,
    //     "SMOOTH": 0,
    //     "RTP": 0
    //   }
  };
};

export interface StreamTarget {
  entryName: string;
  sessionStatus: 'NotFound' | 'Active' | 'Error';
  sourceStreamName: string;
  host: string;
  application: string;
}

// TODO: type
export type ServerToClientChatMsgsOrderUpdate = any;
export type ServerToClientCurrentPresenters = number;
export type ServerToClientDeleteChatMsg = any;
export type ServerToClientDiscardChatMsg = any;
export type ServerToClientEventFieldsUpdated = any;
export type ServerToClientEventUpdated = any;
export type ServerToClientLiveSessionCreated = any;
export type ServerToClientLiveSessionEnded = any;
export type ServerToClientLiveSessionUpdated = any;
export type ServerToClientPollTriggered = any;
export type ServerToClientPresenterSlideChange = any;
export type ServerToClientProcessingReady = any;
export type ServerToClientSendChatMsg = any;
export type ServerToClientSlidesVideo = any;
export type ServerToClientSubmitReaction = any;
export type ServerToClientToPresenterChatMsg = any;
export type ServerToClientUpdateChatMsg = any;

export type ServerToClient = {
  chatMsgsOrderUpdate: ServerToClientChatMsgsOrderUpdate;
  currentOskariOnlineMembers: ServerToClientCurrentOskariOnlineMembers;
  currentPresenters: ServerToClientCurrentPresenters;
  currentViewers: ServerToClientCurrentViewers;
  deleteChatMsg: ServerToClientDeleteChatMsg;
  discardChatMsg: ServerToClientDiscardChatMsg;
  eventFieldsUpdated_v2: ServerToClientEventFieldsUpdatedPayload_v2;
  eventFieldsUpdated: ServerToClientEventFieldsUpdated;
  eventSaved: ServerToClientEventSavedPayload;
  eventStateUpdated: ServerToClientEventStateUpdated;
  eventStateUpdatedRealTime: ServerToClientEventStateUpdated;
  eventUpdated: ServerToClientEventUpdated;
  generateSubtitles: ServerToClientGenerateSubtitles;
  liveSessionCreated: ServerToClientLiveSessionCreated;
  liveSessionEnded: ServerToClientLiveSessionEnded;
  liveSessionUpdated: ServerToClientLiveSessionUpdated;
  pollAnswersUpdated: ServerToClientPollAnswersUpdated;
  pollTriggered: ServerToClientPollTriggered;
  presenterSlideChange: ServerToClientPresenterSlideChange;
  processingReady: ServerToClientProcessingReady;
  sendChatMsg: ServerToClientSendChatMsg;
  slideChange: ServerToClientSlideChange;
  slidesVideo: ServerToClientSlidesVideo;
  streamState: ServerToClientStreamState;
  submitReaction: ServerToClientSubmitReaction;
  toPresenterChatMsg: ServerToClientToPresenterChatMsg;
  updateChatMsg: ServerToClientUpdateChatMsg;
  videoProcessing: ServerToClientVideoProcessing;
  videoProcessingError: ServerToClientVideoProcessingError;
  webStudioEvent: ServerToClientWebStudioEvent;
  tokenConnected: ServerToClientTokenConnected;
  tokenDisconnected: ServerToClientTokenDisconnected;
};
